var env = "local"
var configration = {};

if (env === "local") {
    configration = {
        // BACKEND_URL: "http://localhost:8000/",
        BACKEND_URL: "https://staging-swap-api.d-ecosystem.io/",

        SECRET_KEY: "DEX007",


         DCX : {
            id: 5272,
            name: 'D Chain Mainnet',
            network: 'D-Chain',
            nativeCurrency: {
              decimals: 18,
              name: 'D-Chain',
              symbol: 'DCX',
            },
            rpcUrls: {
              public: { http: ["https://mainnetcoin.d-ecosystem.io/"] },
              default: { http: ["https://mainnetcoin.d-ecosystem.io/"] },
            },
            blockExplorers: {
              etherscan: { name: 'D-Chain', url: "https://mainnet-explorer.d-ecosystem.io/" },
              default: { name: 'D-Chain', url: "https://mainnet-explorer.d-ecosystem.io/" },
            },
            
          },

        D_Router: {
            Dchain: {
                dcx_Router_Contract: "0x555E062745e55caE43289c22F036071Ad6B41A81", //aug21
                dcx_Factory_Contract: "0x99f7D9247c2D8e78f3d67be54D460D9A798a02c6"
            },
            Eth: {
                D_Ethereum_Router: "0x0000000000000000000000000000000000000000",
                D_Ethereum_Factory: "0x0000000000000000000000000000000000000000"
            },
            Bnb: {
                D_BNB_Router: "0x6230cf22eFBd45F15a67ad05b7bd912577285456",//sep4
                D_BNB_Factory: "0x42d26C19a7018017869125CC68D5f95a82BF68E4",
            },
            poly: {
                D_Polygon_Router: "0x26b59A37db7ec0C3ef5912DF0af7ABf027B9Be3e",//sep4
                D_Polygon_Factory: "0x3cF754D7a98D4033b447D3b02EF30f697D50A45B",
            }
        },
        M_Router: {
            Eth: {
                eth_multiRouter: "0x413C1390bF00517d9cAF6229690D3c546b929B51",
            },
            Bnb: {
                bsc_multiRouter: "0x632FCc4af200e3B50A8f8a5C7831C1C6559532f2",//jul16
            },
            poly: {
                poly_multiRouter: "0x5a083f75aeb885f6cb411aeDC5401852a00bB794",//JUL12
            }
        },
        Dcx_Stake: {
            DCOIN_Stake: "0xAE38b6B88E719f98029247c7E7a4BCa38d840aab",
        },
        Lp_stake: {
            Flexible: {
                Dchain: {
                   Stake: "0x8b13C7517Ade8CA13071a8C8671342926716fD82",//oct17 lpflixiblestake
                //    Stake: "0x1D89b93F626254A8bcaFaA7693c0ef20d85f3025",
                },
                Eth: {
                    Stake: "0x27250bB3a727e094a12AB105A26a8Cc7edE24660",
                },
                Bnb: {
                    Stake: "0x216e2343a0519CF70F7dd789a69B6a2DB27d564e",//MAY23
                },
                poly: {
                    Stake: "0xcAD84C3819Ee3F23f09a213fD156d31ed68a52C8",//may28
                }
            },
            locked: {
                Dchain: {
                    D_lock_stake: "0x4F7b47cA24C4861c333D4d2AB46d327E09351a48", //aug30
                },
                Eth: {
                    eth_lock_stake: "0x1fBD4DB9016EFde2ea34b677d50F380d5a24B40e",
                },
                Bnb: {
                    bsc_lock_stake: "0xb4c1527eD557d73655509D64E12f3274D5AcAb46",//MY23
                },
                poly: {
                    poly_lock_stake: "0xefe3D2385485837F171119d90819f5af4FaAa67A",//may28
                }
            }

        },
        Rpc: {
            Dchain: {
                rpc: "https://mainnetcoin.d-ecosystem.io/",
                chainId: 5272,
                chainName: 'D Chain Mainnet'
            },
            Eth: {
                rpc: "https://ethereum-rpc.publicnode.com",
                chainId: 1,
                chainName: 'Ethereum'
            },
            Bnb: {
                rpc: "https://bsc-dataseed1.binance.org/",
                chainId: 56,
                chainName: 'BNB Smart Chain'
            },
            poly: {
                rpc: "https://polygon-mainnet.infura.io/v3/5c9938273605466fac46966815783785",
                chainId: 137,
                chainName: 'Polygon'
            }
        }

    }
} else if (env === "demo") {
    configration = {
      
    }

} else {
    configration = {

    }
}

export default configration;