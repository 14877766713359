import React, { useState, useEffect } from 'react';
import { getisAdmincheck } from "../../api/authapi"
import * as yup from "yup";

export const CheckTokenIslogin = async () => {
    try {
        let Tkvalue = [];
        const reponse = await getisAdmincheck();

        console.log('reponse', reponse.data)

        if (reponse.status === false) {
            return false;
        }

        Tkvalue = reponse.data;

        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        let decoded_id = decodedToken.AdminId
        // console.log('decoded_id', decoded_id)

        const isAdminValid = Tkvalue.some(element => element._id === decoded_id);

        return isAdminValid

    } catch (error) {
        console.log("CheckTokenIslogin error", error);
        if (error) {
            console.log("InvalidTokenError");
            return false;
        }
    }
}



const MAX_FILE_SIZE = 5242880; // 5MB in bytes
const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'webp'] };

function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}
export const blogschema = yup.object().shape({
    blogtitle: yup.string().required("blogtitle field required"),
    bloglink: yup.string().required("blogtitle field required"),
    blogoptions: yup.string().required("blogoptions field required"),
    blogmetatitle: yup.string().required("blogmetatitle field required"),
    blogmetadescription: yup.string().required("blogmetadescription field required"),
    blogmetakey: yup.string().required("blogmetakey field required"),
    blogimage: yup.mixed()
        .test("is-valid-type", "Only ['jpg', 'png', 'jpeg', 'webp'] are allowed as valid image types.",
            value => isValidFileType(value && value.name.toLowerCase(), "image"))
        .test("is-valid-size", "Max allowed size is 5MB",
            value => value && value.size <= MAX_FILE_SIZE)
        .required("blogimage field required"),
    blogcontent: yup.string()
        .test("test-content", "blogcontent field required", (val) => {
            if (val.trim() === "<p><br><\/p>") {
                return false
            } else {
                return true
            }
        })
        .required("blogcontent field required"),
});



export const editErrSchema = yup.object().shape({
    blogtitle: yup.string().required("blogtitle field required"),
    bloglink: yup.string().required("blogtitle field required"),
    // blogoptions: yup.string().required("blogoptions field required"),
    metatitle: yup.string().required("blogmetatitle field required"),
    metadescription: yup.string().required("blogmetadescription field required"),
    metakey: yup.string().required("blogmetakey field required"),
    blogimage: yup.mixed()
        .test("is-valid-type", "Only ['jpg', 'png', 'jpeg', 'webp'] are allowed as valid image types.",
            value => {
                if (value.name === undefined) {
                    return true
                } else {
                    return isValidFileType(value && value.name.toLowerCase(), "image")
                }
            })
        .test("is-valid-size", "Max allowed size is 5MB",
            value => {
                if (value.size === undefined) {
                    return true
                } else {
                    return value && value.size <= MAX_FILE_SIZE
                }
            })
        .required("blogimage field required"),
    blogcontent: yup.string()
        .test("test-content", "blogcontent field required", (val) => {
            if (val.trim() === "<p><br><\/p>") {
                return false
            } else {
                return true
            }
        })
        .required("blogcontent field required"),
})


export const shortenAddress = (address) => {
    if (!address || address.length < 10) return address;
    return `${address.slice(0, 6)}...${address.slice(-5)}`;
}

export const copyAddressToClipboard = async (address) => {
    return navigator.clipboard.writeText(address)
        .then(() => {
            console.log("Address copied successfully!");
            return true;
        })
        .catch((error) => {
            console.error("Failed to copy address:", error);
            return false;
        });
}



export async function addPairValidations(inputs) {
    try {
        const error = {};

        const validationRules = {
            chain_Name: "Chain Network Is Required!",
            token_A_address: "Address A Required!",
            token_B_address: "Address B Required!",
            name_A_token: "Name A is Required!",
            name_B_token: "Name B is Required!",
            symbol_A_token: "Symbol A Required!",
            symbol_B_token: "Symbol B Required!",
            logoURI_A: "LogoA Field Required!",
            logoURI_B: "LogoB Field Required!",
            ownpair:"Please specify if this is an OwnPair.",
        };

        for (const field in validationRules) {
            const value = inputs[field];

            if (value) {
                if (value instanceof File) {
                    if (!value.name) {
                        error[field] = validationRules[field];
                    }
                } else if (typeof value === 'string' && value.trim() === "") {
                    error[field] = validationRules[field];
                }
            } else {
                error[field] = validationRules[field];
            }
        }

        return error
    } catch (error) {
        console.log('addPairValidations Error:>>', error);
    }
}


export const UpdateValidations = (inputs) =>{
    try {
        const error = {};

        const validationRules = {
            chain_Name: "Chain Network Is Required!",
            token_A_address: "Address A Required!",
            token_B_address: "Address B Required!",
            name_A_token: "Name A is Required!",
            name_B_token: "Name B is Required!",
            symbol_A_token: "Symbol A Required!",
            symbol_B_token: "Symbol B Required!",
            logoURI_A: "LogoA Field Required!",
            logoURI_B: "LogoB Field Required!",
        };

        for (const field in validationRules) {
            const value = inputs[field];

            if (value) {
                if (value instanceof File) {
                    if (!value.name) {
                        error[field] = validationRules[field];
                    }
                } else if (typeof value === 'string' && value.trim() === "") {
                    error[field] = validationRules[field];
                }
            } else {
                error[field] = validationRules[field];
            }
        }
          
        return error
    } catch (error) {
        console.log('UpdateValidations Error:>>', error);
    }
}
