import { makeApiRequest } from "../axios/Axiosservices";


export const loginRequest = async (data) => {
  try {
    console.log('data--login', data);

    let params = {
      url: "Admin/Login",
      method: "POST",
      data: { enData: data }
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.error("loginRequest", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}


export const otpRequest = async (data) => {
  try {
    let params = {
      url: "Admin/verifyOtp",
      method: "POST",
      data: { enData: data }
    }

    const response = await makeApiRequest(params);
    return {
      data: response.token,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("loginRequest", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const forgetRequest = async (data) => {
  try {
    let params = {
      url: "Admin/forgetpass",
      method: "POST",
      data: { enData: data }
    }

    const response = await makeApiRequest(params);
    return {
      data: response.userToken,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("loginRequest", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const resetRequest = async (data) => {
  try {
    let params = {
      url: "Admin/resetpass",
      method: "POST",
      data: { enData: data }
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("loginRequest", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const getisAdmincheck = async () => {
  try {

    let params = {
      url: "Admin/getadmincheck",
      method: "GET",
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.error("getisAdmincheck", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const blogsAdd = async (data) => {
  try {
    let params = {
      url: "Admin/AddBlogs",
      method: "POST",
      data: data
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("blogsAdd Error:", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const blogsEdit = async (data) => {
  try {
    let params = {
      url: `Admin/EditBlogs`,
      method: "POST",
      data: data
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("blogsEdit Error:", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const blogidDelete = async (id) => {
  try {
    let params = {
      url: `Admin/deleteblog`,
      method: "POST",
      data: id
    }

    const response = await makeApiRequest(params);
    return {
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("blogidDelete Error:", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const getcategorylist = async () => {
  try {

    let params = {
      url: "Admin/categorylist",
      method: "GET",
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.error("getisAdmincheck Error", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}


export const getblogdetails = async (page, perpage) => {
  try {

    let params = {
      url: `Admin/getblogs?page=${page}&pageSize=${perpage}`,
      method: "GET",
    }

    const response = await makeApiRequest(params);
    return {
      data: response.blogdata,
      status: response.status,
      message: response.message,
      totalRecords: response.alldata,
    }

  } catch (error) {
    console.error("getisAdmincheck Error:", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}

export const findblogdetails = async (id) => {
  try {

    let params = {
      url: `Admin/getblogsid/${id}`,
      method: "GET",
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.error("getisAdmincheck Error", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}


export const categoryAdd = async (data) => {
  try {
    let params = {
      url: `Admin/blogcategoryAdd`,
      method: "POST",
      data: data
    }

    const response = await makeApiRequest(params);
    return {
      data: response.data,
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("categoryAdd Error", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}


export const otpResendRequest =async (data)=>{
  try {
    let params = {
      url: "Admin/otpResend",
      method: "POST",
      data: { enData: data }
    }

    const response = await makeApiRequest(params);
    return {
      status: response.status,
      message: response.message
    }
  } catch (error) {
    console.error("loginRequest", error)
    return {
      status: false,
      message: "Error On Server"
    }
  }
}