import React from 'react'
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
import render from "../assets/images/favicon.png"

function Form() {


    const columnsone = [
        {
            name: '',
            selector: row => row.image,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'TVL',
            selector: row => row.tvl,
            sortable: true,
        },
        {
            name: 'POOL',
            selector: row => row.pool,
            sortable: true,
        },
        {
            name: 'TOKEN A',
            selector: row => row.tokena,
            sortable: true,
        },
        {
            name: 'TOKEN B',
            selector: row => row.tokenb,
            sortable: true,
        },
        {
            name: 'BUTTON 1',
            selector: row => row.button1,
            sortable: true,
        },
        {
            name: 'BUTTON 2',
            selector: row => row.button2,
            sortable: true,
        },
        {
            name: 'BUTTON 3',
            selector: row => row.button3,
            sortable: true,
        },

    ];
    const dataone = [
        {
            id: 1,
            image:<div><img className='custom-table-img' src={render}></img></div>,
            name: 'DINU/CINU',
            tvl: <div>$7.01</div>,
            pool: <div>0x5d4…7a1ac</div>,
            tokena:
                <div>
                    hhhhhhhhhhhhh
                </div>,
            tokenb:
                <div>
                    1
                </div>,
            button1:
                <div>
                    <button className='liquidity-btn-1'>V2</button>
                </div>,
            button2:
                <div>
                    <button className='liquidity-btn-1'>o.17%</button>
                </div>,
            button3:
                <div>
                    <button className='liquidity-btn-1'><CiCirclePlus /></button>
                </div>,
        },
        {
            id: 2,
            image: <div><img className='custom-table-img' src={render}></img></div>,
            name: 'DCX/DICO',
            tvl: <div>$7.01</div>,
            pool: <div>0x5d4…7a1ac</div>,
            tokena:
                <div>
                    hhhhhhhhhhhhh
                </div>,
            tokenb:
                <div>
                    1
                </div>,
            button1:
                <div>
                    <button className='liquidity-btn-1'>V2</button>
                </div>,
            button2:
                <div>
                    <button className='liquidity-btn-1'>o.17%</button>
                </div>,
            button3:
                <div>
                    <button className='liquidity-btn-1'><CiCirclePlus /></button>
                </div>,
        },
    ];


    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-form min-vh-100'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <h2>Forms</h2>
                                        <div className='custom-inside-form'>
                                            <div className='custom-inside-liquidity'>
                                                <div class="row g-2 align-items-center">
                                                    <div class="col-auto">
                                                        <label for="inputPassword6" class="col-form-label text-white">Swap Admin Fee :</label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <input type="text" id="inputPassword6" class="form-control input-text-1" aria-describedby="passwordHelpInline" placeholder='ggggg' />
                                                    </div>
                                                </div>
                                                <div class="row g-2 align-items-center mt-3">
                                                    <div class="col-auto">
                                                        <label for="inputPassword6" class="col-form-label text-white">Swap Admin Fee :</label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <input type="text" id="inputPassword6" class="form-control input-text-1" aria-describedby="passwordHelpInline" placeholder='ggggg' />
                                                    </div>
                                                </div>
                                                <div className='text-center mt-3'>
                                                    <button className='liquidity-btn-1'>Submit</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='liquidity-table-1 mt-3'>
                                            <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form