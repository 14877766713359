import { makeApiRequest } from "../axios/Axiosservices";


export const getAllPool = async (data, searchQuery, currentPage) => {
    try {

        const payload = {
            netWorkname: data,
        };
        let params = {
            url: `Admin/getPools?query=${searchQuery}&page=${currentPage}`,
            method: "POST",
            data: payload
        }

        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.liquidityPairs,
            totalItems: response.totalItems,
            currentPage: response.currentpage
        }

    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}


export const setFroms = async (datas) => {
    try {

        const payload = {
            data: datas,
        };

        let params = {
            url: `Admin/addFroms`,
            method: "POST",
            data: payload
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const getFroms = async (chain, searchData, currentPage) => {
    try {

        const payload = {
            netWorkname: chain,
        };
        let params = {
            url: `Admin/getFroms?query=${searchData}&page=${currentPage}`,
            method: "POST",
            data: payload
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.liquidityPairs,
            totalItems: response.totalItems,
            currentPage: response.currentpage
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}
//updateFormStatus

export const updateFormStatus = async (data) => {
    try {

        let params = {
            url: `Admin/updateFormStatus`,
            method: "POST",
            data: data
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.data,
            message:response.message,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const allForms = async (data) => {
    try {

        let params = {
            url: `Admin/allForms`,
            method: "POST",
            data: data
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.data,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}



export const allTradePairsData = async (data) => {
    try {

        let params = {
            url: `Admin/allTradePairs`,
            method: "POST",
            data: data
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.data,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}



export const getTrade = async (chain, searchData, currentPage) => {
    try {

        const payload = {
            netWorkname: chain,
        };
        let params = {
            url: `Admin/getTrade?query=${searchData}&page=${currentPage}`,
            method: "POST",
            data: payload
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.liquidityPairs,
            totalItems: response.totalItems,
            currentPage: response.currentpage
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const updateTradeStatus = async (data) => {
    try {

        let params = {
            url: `Admin/updateTradeStatus`,
            method: "POST",
            data: data
        }
        const response = await makeApiRequest(params);
        return {
            status: response.status,
            data: response.data,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const updatePair = async (data) => {
    try {

        let params = {
            url: `Admin/updatePair`,
            method: "POST",
            data: data
        }
        console.log(params)
        const response = await makeApiRequest(params);
        return {
            status: response.status,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const setTradePair = async (data, OwnPairStatus) => {
    try {

        const payload = {
            data: data,
            status: OwnPairStatus
        }

        let params = {
            url: `Admin/addTradePair`,
            method: "POST",
            data: data
        }

        const response = await makeApiRequest(params);
        return {
            status: response.status,
            message: response.message,
            data:response.data
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const deletePairTrade = async (data) => {
    try {
        const payload = {
            data: data,
        }
        let params = {
            url: `Admin/deletePairTrade`,
            method: "POST",
            data: payload
        }
        console.log("params=======",params)
        const response = await makeApiRequest(params);
        return {
            status: response.status,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}

export const updatePoolStatus = async (data) => {
    try {

        let params = {
            url: `Admin/PoolsvisableMode`,
            method: "POST",
            data: data
        }

        const response = await makeApiRequest(params);
        return {
            status: response.status,
            message:response.message,
            data:response.data,
        }
    } catch (error) {
        console.error("loginRequest", error)
        return {
            status: false,
            message: "Error On Server"
        }
    }
}
