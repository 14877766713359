
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CiCirclePlus } from "react-icons/ci";
import { getAllPool, setTradePair, updateTradeStatus, getTrade, allTradePairsData, updatePair, deletePairTrade } from "../api/swapControlapi";
import { IoIosSearch } from "react-icons/io";
import { BsPlusCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import ethereum from "../assets/images/ethereum.png";
import polygon from "../assets/images/polygon.png";
import bnb from "../assets/images/bnb.png";
import dcx from '../assets/images/dchainimages.png';
import config from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import stakeContractAbi from "../Abi/Stake.json";
import Web3, { ERR_CONTRACT_RESOLVER_MISSING } from 'web3';
import { GrClearOption } from "react-icons/gr";
import Swal from "sweetalert2";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
    useNetwork,
    useSwitchNetwork,
} from "wagmi";
// import metamask from "../assets/images/metamask.png";
import Select from 'react-select';
import tokenAbi from '../Abi/tokenabi.json';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { RiCloseLargeLine } from "react-icons/ri";
import { Tooltip } from 'react-tooltip';
import { shortenAddress, copyAddressToClipboard, addPairValidations, UpdateValidations } from './utils/helper';

function TradePair(props) {

    const { data } = props;
    const { connect, connectors, isLoading, pendingConnector } = useConnect();
    const { chain } = useNetwork();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { chains, pendingChainId, error, switchNetwork } = useSwitchNetwork();
    const { address, isConnected } = useAccount();
    const { data: ensName } = useEnsName({ address });
    const { data: ensAvatar } = useEnsAvatar({ name: ensName });
    const trigerWalletRef = useRef(null);
    const { disconnect } = useDisconnect();
    const [searchInPositionTable, setSearchInPositionTable] = useState('');
    const [searchInPositionTableTrade, setSearchInPositionTableTrade] = useState('');
    const [chainListTableData, setChainListTableData] = useState([]);
    const [chainIdList, setChainIdList] = useState([5272]);
    const [usercurrentPage, setUserCurrentPage] = useState(1);
    const [allPoolTable, setAllPoolTable] = useState([]);
    const [tableStatus, setTableStatus] = useState(1);
    const [allTradeData, setAllTradeData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionOwnPair, setSelectedOptionOwnPair] = useState(null);
    const [show, setShow] = useState(false);
    const [newpair, setnewpair] = useState(false);
    const [pairerrors, setPairerrors] = useState({})
    const [upadtePairError, setUpadtePairError] = useState({})

    const [tradeData, setTradeData] = useState({
        name_A_token: '',
        symbol_A_token: '',
        name_B_token: '',
        symbol_B_token: '',
        token_A_address: '',
        token_B_address: '',
        logoURI_A: null,
        logoURI_B: null,
        chain_Id: '',
        chain_Name: '',
        ownpair: '',
    });
    const [tradeDataUpdate, setTradeDataUpdate] = useState({
        _id: "",
        name_A_token: '',
        symbol_A_token: '',
        name_B_token: '',
        symbol_B_token: '',
        token_A_address: '',
        token_B_address: '',
        logoURI_A: null,
        logoURI_B: null,
        chain_Id: '',
        chain_Name: ''
    });
    const [preview, setpreview] = useState(({
        preview_A: null,
        preview_B: null,
    }))
    const [Upadteimage, setUpadteimage] = useState({
        Logo_A: null,
        Logo_B: null,
    })

    const [fromsTable, setFromTable] = useState([]);
    const handleClose = () => {
        setShow(false)
        setUpadtePairError({})
    };

    const getAllpools = async (data) => {
        try {
            let networksDatas;
            if (data.length > 0) {
                networksDatas = data;
            } else {
                networksDatas = ['Polygon', 'D Chain Mainnet', 'BNB Smart Chain', 'Ethereum']
            }
            const res = await getAllPool(networksDatas, searchInPositionTable, usercurrentPage);
            if (res.status == true) {
                setAllPoolTable(res.data)
            } else {
                setAllPoolTable([]);
            }
        } catch (error) {
            console.log(error);
        }
    }



    const getAllTradepair = async () => {
        try {
            const res = await allTradePairsData();
            if (res.status === true) {
                setAllTradeData(res.data);
            } else {
                setAllTradeData([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getTradeTable = async (chain, searchData) => {
        try {
            let arr;
            if (chain.length > 0) {
                arr = chain;
            } else {
                arr = ['Polygon', 'D Chain Mainnet', 'BNB Smart Chain', 'Ethereum']
            }
            const data = await getTrade(arr, searchData, usercurrentPage);
            if (data.status == true) {
                setFromTable(data.data);
            } else {
                setFromTable([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => { getAllTradepair() }, [])
    useEffect(() => {
        if (Number(tableStatus) === 0) {
            getAllpools(chainListTableData);
        } else {
            getTradeTable(chainListTableData, searchInPositionTableTrade);
        }
    }, [tableStatus, usercurrentPage, searchInPositionTable, searchInPositionTableTrade, chainListTableData]);


    const showCheck = async (data) => {
        try {
            let updatedCheckStatusList = [...chainListTableData];
            let updatedChainIdList = [...chainIdList];

            if (data === 1) {
                if (chainListTableData.includes('Ethereum') && chainIdList.includes(1)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'Ethereum');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 1);
                } else {
                    updatedCheckStatusList.push('Ethereum');
                    updatedChainIdList.push(1);
                }
            } else if (data === 2) {
                if (chainListTableData.includes('BNB Smart Chain') && chainIdList.includes(56)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'BNB Smart Chain');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 56);
                } else {
                    updatedCheckStatusList.push('BNB Smart Chain');
                    updatedChainIdList.push(56);
                }
            } else if (data === 3) {
                if (chainListTableData.includes('Polygon') && chainIdList.includes(137)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'Polygon');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 137);
                } else {
                    updatedCheckStatusList.push('Polygon');
                    updatedChainIdList.push(137)
                }
            } else {
                if (chainListTableData.includes('D Chain Mainnet') && chainIdList.includes(5272)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'D Chain Mainnet');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 5272);
                } else {

                    updatedCheckStatusList.push('D Chain Mainnet');
                    updatedChainIdList.push(5272);
                }
            }
            setChainListTableData(updatedCheckStatusList);
            setChainIdList(updatedChainIdList);
        } catch (error) {
            console.log(error);
        }
    }


    const handleUpdateSetup = (data) => {
        try {
            if (data) {
                setTradeDataUpdate({
                    _id: data._id,
                    name_A_token: data.name_A_token,
                    symbol_A_token: data.symbol_A_token,
                    name_B_token: data.name_B_token,
                    symbol_B_token: data.symbol_B_token,
                    token_A_address: data.token_A_address,
                    token_B_address: data.token_B_address,
                    logoURI_A: data.logoURI_A,
                    logoURI_B: data.logoURI_B,
                    chain_Id: data.chain_Id,
                    chain_Name: data.chain_Name
                })
                setUpadteimage({
                    Logo_A: data.logoURI_A,
                    Logo_B: data.logoURI_B,
                })
                setShow(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addPoolsSetUp = (data) => {
        try {
            if (data) {
                setTradeData({
                    name_A_token: data.name_A_token,
                    symbol_A_token: data.symbol_A_token,
                    name_B_token: data.name_B_token,
                    symbol_B_token: data.symbol_B_token,
                    token_A_address: data.token_A_address,
                    token_B_address: data.token_B_adderss,
                    logoURI_A: data.logoURI_A,
                    logoURI_B: data.logoURI_B,
                    chain_Id: data.chain_Id == config.Rpc.Eth.chainName ? config.Rpc.Eth.chainId : data.chain_Id == config.Rpc.Bnb.chainName ? config.Rpc.Bnb.chainId :
                        data.chain_Id == config.Rpc.poly.chainName ? config.Rpc.poly.chainId : data.chain_Id == config.Rpc.Dchain.chainName ? config.Rpc.Dchain.chainId : 0,
                    chain_Name: data.chain_Id
                })
                let obj = {
                    chainId: data.chain_Id == config.Rpc.Eth.chainName ? config.Rpc.Eth.chainId : data.chain_Id == config.Rpc.Bnb.chainName ? config.Rpc.Bnb.chainId :
                        data.chain_Id == config.Rpc.poly.chainName ? config.Rpc.poly.chainId : data.chain_Id == config.Rpc.Dchain.chainName ? config.Rpc.Dchain.chainId : 0,
                    chainName: data.chain_Id,
                    label: data.chain_Id == config.Rpc.Eth.chainName ? 'Ethereum' : data.chain_Id == config.Rpc.Bnb.chainName ? "Bsc" :
                        data.chain_Id == config.Rpc.poly.chainName ? 'Polygon' : data.chain_Id == config.Rpc.Dchain.chainName ? 'Dchain' : "",
                    rpc: data.chain_Id == config.Rpc.Eth.chainName ? config.Rpc.Eth.rpc : data.chain_Id == config.Rpc.Bnb.chainName ? config.Rpc.Bnb.rpc :
                        data.chain_Id == config.Rpc.poly.chainName ? config.Rpc.poly.rpc : data.chain_Id == config.Rpc.Dchain.chainName ? config.Rpc.Dchain.rpc : '',
                }

                setSelectedOption(obj);
                setShow1(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const columnsone = [
        {
            name: 'S.no',
            selector: row => row.number,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Chain',
            selector: row => row.chain,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Pool',
            selector: row => row.pool,
            sortable: true,
        },
        {
            name: 'Token A',
            selector: row => row.tokena,
            sortable: true,
        },
        {
            name: 'Token B',
            selector: row => row.tokenb,
            sortable: true,
        },
        {
            name: 'AddPool',
            selector: row => row.AddPool,
            sortable: true,
            width: "150px"
        }

    ];

    const dataone = allPoolTable && allPoolTable.map((data, index) => {
        return {
            id: index + 1,
            number: <div>{index + 1}</div>,
            name: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={`${data.name_A_token}/${data.name_B_token}`}>{`${data.name_A_token}/${data.name_B_token}`}</div>,
            chain: data.chain_Id == config.Rpc.Dchain.chainName ? "Dchain" : data.chain_Id == config.Rpc.poly.chainName ? "Polygon" :
                data.chain_Id == config.Rpc.Bnb.chainName ? "Bsc" : data.chain_Id == config.Rpc.Eth.chainName ? "Ethereum" : "",
            pool: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={data.pair_Address} onClick={() => copydata(data.pair_Address)}>{shortenAddress(data.pair_Address)}</div>,
            tokena: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={data.token_A_address} onClick={() => copydata(data.token_A_address)}>{shortenAddress(data.token_A_address)}</div>,
            tokenb: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={data.token_B_adderss} onClick={() => copydata(data.token_B_adderss)}>{shortenAddress(data.token_B_adderss)}</div>,
            AddPool: <div><button className='liquidity-btn-1' onClick={() => { addPoolsSetUp(data) }}> ADD </button></div>,
        };
    });


    const handleStatusChange = async (data) => {
        try {

            if (data) {
                console.log(data)
                let t1 = data.symbol_A_token
                let msgpool = t1.concat("/", data.symbol_B_token);
                const payload = {
                    _id: data._id,
                    status: data.status === true ? false : true
                }
                const res = await updateTradeStatus(payload);
                let toastResult = res.data ? "Active" : "In Active";
                if (res.status === true) {
                    toast(`${msgpool} Pair Status ${toastResult} Successfully!`);
                    getTradeTable(chainListTableData, searchInPositionTableTrade);
                } else {
                    toast('Failed');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    const columnstwo = [
        {
            name: 's.no',
            selector: row => row.number,
            sortable: true,
            width: "90px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'chain',
            selector: row => row.chain,
            sortable: true,
        },
        {
            name: 'TOKEN A',
            selector: row => row.tokena,
            sortable: true,
            width: "150px",
        },
        {
            name: 'TOKEN B',
            selector: row => row.tokenb,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Active',
            selector: row => row.button1,
            sortable: true,
            width: "150px",

        },
        {
            name: 'Edit',
            selector: row => row.button2,
            sortable: true,
            width: "150px",

        },
        {
            name: 'Delete',
            selector: row => row.button3,
            sortable: true,
            width: "150px",

        }

    ];

    const datatwo = fromsTable && fromsTable.map((data, index) => {

        return {
            id: index + 1,
            number: <div>{index + 1}</div>,
            name: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={`${data.symbol_A_token}/${data.symbol_B_token}`} >{`${data.symbol_A_token}/${data.symbol_B_token}`}</div>,
            chain: data.chain_Id == config.Rpc.Dchain.chainId ? "Dchain" : data.chain_Id == config.Rpc.poly.chainId ? "Polygon" :
                data.chain_Id == config.Rpc.Bnb.chainId ? "Bsc" : data.chain_Id == config.Rpc.Eth.chainId ? "Ethereum" : "",
            tokena: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={data.token_A_address} onClick={() => copydata(data.token_A_address)}>{shortenAddress(data.token_A_address)}</div>,
            tokenb: <div style={{ cursor: "pointer" }} data-tooltip-id="Tradepair-tooltip" data-tooltip-content={data.token_B_address} onClick={() => copydata(data.token_B_address)}>{shortenAddress(data.token_B_address)}</div>,
            button1: <div><button className={`liquidity-btn-1 ${data.status === true ? "active-btn" : "inactive-btn"}`} onClick={() => { handleStatusChange(data) }}>{data.status === true ? 'Active' : "In Active"}</button> </div>,
            button2: <div variant="primary"> <button className='liquidity-btn-1' onClick={() => { handleUpdateSetup(data) }}> Edit</button></div>,
            button3: <div variant="primary"> <button className='liquidity-btn-1' onClick={() => { handleDelete(data) }}> Delete</button></div>
        }
    })


    const options = [
        { chainId: config.Rpc.Eth.chainId, chainName: config.Rpc.Eth.chainName, rpc: config.Rpc.Eth.rpc, label: 'Ethereum' },
        { chainId: config.Rpc.Bnb.chainId, chainName: config.Rpc.Bnb.chainName, rpc: config.Rpc.Bnb.rpc, label: 'Bsc' },
        { chainId: config.Rpc.poly.chainId, chainName: config.Rpc.poly.chainName, rpc: config.Rpc.poly.rpc, label: 'Polygon' },
        { chainId: config.Rpc.Dchain.chainId, chainName: config.Rpc.Dchain.chainName, rpc: config.Rpc.Dchain.rpc, label: 'Dchain' },
    ];
    const optionsOwnPair = [
        { value: true, label: "True" },
        { value: false, label: "false" }
    ];
    const handleChangeOwnPair = (option) => {
        setSelectedOptionOwnPair(option);
    };

    const handleChange = (option) => {
        console.log(option)
        setSelectedOption(option);
        setTradeData(p => ({
            ...p,
            chain_Id: option.chainId,
            chain_Name: option.chainName
        }))
        setPairerrors(p => ({
            ...p,
            chain_Name: ""
        }))
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#004080'
                : state.isFocused
                    ? '#3659db'
                    : provided.backgroundColor,
            color: state.isSelected
                ? 'white'

                : provided.color,
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#004080',
            color: 'white',
        }),
        singleValue: (provided) => ({
            ...provided,

            color: 'black',
        }),
    };

    const handleClear = () => {
        try {
            setTradeData(prevData => ({
                ...prevData,
                name_A_token: '',
                symbol_A_token: '',
                name_B_token: '',
                symbol_B_token: '',
                token_A_address: '',
                token_B_address: '',
                logoURI_A: '',
                logoURI_B: '',
                chain_Id: '',
                chain_Name: '',
                ownpair: '',
            }))
            setSelectedOptionOwnPair(null);
            setSelectedOption(null);
            setpreview({
                preview_A: null,
                preview_B: null,
            })
            setPairerrors({})
        } catch (error) {
            console.log(error);
        }
    }

    const onOptionChange = (e) => {
        const { name, value } = e.target;
        const nameRadio = "ownpair"

        if (name === "isOwnPair") {
            setTradeData(prevData => ({ ...prevData, [nameRadio]: "isOwnPair" }));
        }
        if (name === "isNotOwnPair") {
            setTradeData(prevData => ({ ...prevData, [nameRadio]: "isNotOwnPair" }));
        }
        setPairerrors(p => ({
            ...p,
            [nameRadio]: ""
        }))
    }

    const handleChangeLogo = (e, logourl, previewKey) => {
        const file = e.target.files[0]
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTradeData(prevData => ({ ...prevData, [logourl]: file }))
                setpreview(prevData => ({ ...prevData, [previewKey]: reader.result }))
            }
            reader.readAsDataURL(file);
            setPairerrors(p => ({
                ...p,
                [logourl]: ""
            }))
        } else {
            alert("Please select an image file ('jpg', 'png' ,'jpeg','webp'.)")
        }

    }

    const handleChangeSymbol = (e, symbol) => {
        const { value } = e.target;
        const lettersOnly = /^[a-zA-Z]*$/;
        if (lettersOnly.test(value) || value === '') {
            setTradeData(prevData => ({ ...prevData, [symbol]: value.toUpperCase() }));
        }
        setPairerrors(p => ({
            ...p,
            [symbol]: ""
        }))
    };

    const handleChangeName = (e, name) => {
        const { value } = e.target;
        const lettersAndSpacesOnly = /^[a-zA-Z\s]*$/;
        if (lettersAndSpacesOnly.test(value) || value === '') {
            setTradeData(prevData => ({ ...prevData, [name]: value }));
        }
        setPairerrors(p => ({
            ...p,
            [name]: ""
        }))
    };

    const handleChangeAddress = (e, address) => {
        const { value } = e.target;
        const ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (ethAddressRegex.test(value) || value === '') {
            setTradeData(prevData => ({ ...prevData, [address]: value }));
        }
        setPairerrors(p => ({
            ...p,
            [address]: ""
        }))
        // fetchTokenDetails_A(e, address)
    };

    const fetchTokenDetails_A = async (e, address) => {
        try {
            const ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

            if (ethAddressRegex.test(e.target.value) && selectedOption.rpc) {
                let addressArr = ["0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768", "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"];
                const web3 = new Web3(selectedOption.rpc);
                const contract = new web3.eth.Contract(tokenAbi, e.target.value);
                const [symbol, name] = await Promise.all([
                    contract.methods.symbol().call(),
                    contract.methods.name().call()
                ]);

                if (address === 'token_A_address') {
                    if (addressArr.includes(e.target.value)) {
                        const tokenName = selectedOption.chain_Id === config.Rpc.Eth.chainId ? "Ethereum" : selectedOption.chain_Id === config.Rpc.Bnb.chainId ? "BNB" : selectedOption.chain_Id === config.Rpc.poly.chainId ? "Polygon" : selectedOption.chain_Id === config.Rpc.Dchain.chainId ? "Dcx" : ""
                        const tokenSymbol = selectedOption.chain_Id === config.Rpc.Eth.chainId ? "ETH" : selectedOption.chain_Id === config.Rpc.Bnb.chainId ? "BNB" : selectedOption.chain_Id === config.Rpc.poly.chainId ? "P0L" : selectedOption.chain_Id === config.Rpc.Dchain.chainId ? "DCX" : ""
                        setTradeData(prev => ({
                            ...prev,
                            name_A_token: tokenName,
                            symbol_A_token: tokenSymbol
                        }));
                    } else {
                        setTradeData(prev => ({
                            ...prev,
                            name_A_token: name,
                            symbol_A_token: symbol
                        }));
                    }
                } else {
                    if (addressArr.includes(e.target.value)) {
                        const tokenName = selectedOption.chain_Id === config.Rpc.Eth.chainId ? "Ethereum" : selectedOption.chain_Id === config.Rpc.Bnb.chainId ? "BNB" : selectedOption.chain_Id === config.Rpc.poly.chainId ? "Polygon" : selectedOption.chain_Id === config.Rpc.Dchain.chainId ? "Dcx" : ""
                        const tokenSymbol = selectedOption.chain_Id === config.Rpc.Eth.chainId ? "ETH" : selectedOption.chain_Id === config.Rpc.Bnb.chainId ? "BNB" : selectedOption.chain_Id === config.Rpc.poly.chainId ? "P0L" : selectedOption.chain_Id === config.Rpc.Dchain.chainId ? "DCX" : ""
                        setTradeData(prev => ({
                            ...prev,
                            name_B_token: tokenName,
                            symbol_B_token: tokenSymbol
                        }));
                    } else {
                        setTradeData(prev => ({
                            ...prev,
                            name_B_token: name,
                            symbol_B_token: symbol
                        }));
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleChangeSymbolUpdate = (e, symbol) => {
        const { value } = e.target;
        const lettersOnly = /^[a-zA-Z]*$/;
        if (lettersOnly.test(value) || value === '') {
            setTradeDataUpdate(prevData => ({ ...prevData, [symbol]: value.toUpperCase() }));
        }
        setUpadtePairError(prevErr =>({...prevErr,[symbol]:""}))
    };

    const handleChangeNameUpdate = (e, name) => {
        const { value } = e.target;
        const lettersAndSpacesOnly = /^[a-zA-Z\s]*$/;
        if (lettersAndSpacesOnly.test(value) || value === '') {
            setTradeDataUpdate(prevData => ({ ...prevData, [name]: value }));
        }
        setUpadtePairError(prevErr =>({...prevErr,[name]:""}))
    };

    const handleChangeAddressUpdate = (e, address) => {
        const { value } = e.target;
        const ethAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (ethAddressRegex.test(value) || value === '') {
            setTradeDataUpdate(prevData => ({ ...prevData, [address]: value }));
        }
        setUpadtePairError(prevErr =>({...prevErr,[address]:""}))
    };

    const handleChangeLogoUpdate = (e, Logo, previewKey) => {
        const file = e.currentTarget.files[0];

        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTradeDataUpdate(prevData => ({ ...prevData, [Logo]: file }));
                setUpadteimage(prevData => ({ ...prevData, [previewKey]: reader.result }))
            }

            reader.readAsDataURL(file);
        } else {
            alert("Please select an image file ('jpg', 'png' ,'jpeg','webp'.)")
        }
        setUpadtePairError(prevErr =>({...prevErr,[Logo]:""}))
    }

    const updateSubmit = async () => {
        try {
            const geterror = UpdateValidations(tradeDataUpdate)
            setUpadtePairError(geterror)
            let t1 = tradeDataUpdate.symbol_A_token
            let msgpair = t1.concat("/", tradeDataUpdate.symbol_B_token);
            // console.log('tradeDataUpdate', tradeDataUpdate)
            console.log('error', geterror)

            if (Object.keys(geterror).length === 0) {
                
                // const data = tradeDataUpdate;
                // const formData = new FormData();
                // Object.keys(data).forEach((key) => {
                //     if (key === "logoURI_A" || key === "logoURI_B") {
                //         if (data[key] instanceof File) {
                //             formData.append(key, data[key]);
                //         }
                //         else if (typeof data[key] === "object" && data[key] !== null) {
                //             formData.append(key, JSON.stringify(data[key]));
                //         }
                //     } else {
                //         formData.append(key, data[key]);
                //     }
                // })

                const res = await updatePair(tradeDataUpdate);
                if (res.status == true) {
                    toast(`${msgpair} Pair Update Successfully!`);
                    setShow(false)
                    getTradeTable(chainListTableData, searchInPositionTableTrade);
                } else {
                    toast("Pair Update failed!");
                }
            }
        } catch (error) {
            console.log(error);
            toast("Update failed");
        }
    }



    const handelcheck = (data) => {
        try {

            if (data.name_A_token == "") {
                toast.warn("TokenA name should be filed");
                return false;
            }
            if (data.symbol_A_token == "") {
                toast.warn("TokenA symbol should be filed");
                return false;
            }
            if (data.name_B_token == "") {
                toast.warn("TokenB name should be filed");
                return false;
            }
            if (data.symbol_B_token == "") {
                toast.warn("TokenB symbol should be filed");
                return false;
            }
            if (data.token_B_address == "") {
                toast.warn("TokenB address should be filed");
                return false;
            }
            if (data.token_A_address == "") {
                toast.warn("TokenA address should be filed");
                return false;
            }

            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }


    const handleAddNewPair = async (datas) => {
        try {
            const responce = handelcheck(datas);

            if (responce === false) {
                return;
            }

            if (selectedOptionOwnPair === null) {
                toast("Give ownpair status")
                return;
            }
            const filter = allTradeData.filter((d) =>
                d.chain_Id === datas.chain_Id &&
                (
                    (d.token_A_address === datas.token_A_address && d.token_B_address === datas.token_B_address) ||
                    (d.token_A_address === datas.token_B_address && d.token_B_address === datas.token_A_address)
                )
            );

            if (filter.length === 0) {
                const res = await setTradePair(datas, selectedOptionOwnPair.value);
                if (res.status == true) {
                    toast("Add pair success");
                    getTradeTable(chainListTableData, searchInPositionTableTrade);
                    getAllTradepair();
                    handleClear();
                } else {
                    toast("Add pair failed");
                }

            } else {
                toast("This pair allready here");
            }
        } catch (error) {
            console.log(error);
            toast("Add pair failed");
        }

    }

    const handleDelete = async (data) => {
        try {
            if (data) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    denyButtonText: `Don't save`
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const res = await deletePairTrade(data);
                        if (res.status === true) {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your Pair trade has been deleted.",
                                icon: "success"
                            });
                            await getTradeTable(chainListTableData, searchInPositionTableTrade);
                        } else {
                            Swal.fire("Pair trade deleted Failed.");
                        }

                    } else if (result.isDenied) {
                        Swal.fire("Pair trade deleted Failed.");
                    }
                }
                );
            } else {
                Swal.fire("Pair trade deleted Failed.");
            }
        } catch (error) {
            console.log(error)
        }
    }


    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const copydata = async (data) => {
        const result = await copyAddressToClipboard(data)
        if (result) {
            toast.success("Address Copied", { autoClose: 500 })
        } else {
            toast.error("Address Copied Fail!")
        }
    }


    const createnewpair = async (data) => {
        try {
            const errValue = await addPairValidations(data)
            setPairerrors(errValue)

            if (Object.keys(errValue).length === 0) {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key === "logoURI_A" || key === "logoURI_B") {
                        if (data[key] instanceof File) {
                            formData.append(key, data[key]);
                        }
                        else if (typeof data[key] === "object" && data[key] !== null) {
                            formData.append(key, JSON.stringify(data[key]));
                        }
                    } else {
                        formData.append(key, data[key]);
                    }
                })

                const response = await setTradePair(formData);
                console.log('response', response)
                if (response.status === true) {
                    toast.success(response.message);
                    getTradeTable(chainListTableData, searchInPositionTableTrade);
                    getAllTradepair();
                    handleClear();
                    setnewpair(false);
                    handleClose1();
                } else if (response.message === "valid pair symbol provide!") {
                    toast.warn("valid pair symbol provide!")
                } else if(response.status === 1) {
                    toast.warn(response.message);
                }else{
                    toast.warn("Add pair failed");
                }
            }
        } catch (error) {
            console.log("createnewpair Error:>>", error)
        }
    }

    const Addnewpairdatas = () => {
        try {
            handleClear();
            setShow1(true);
            setnewpair(true);
        } catch (error) {
            console.log('Addnewpairdatas Error:>>', error)
        }
    }

    return (
        <div className='App add-pools'>
            <div className='container-fluid'>
                <div className='custom-add-1 min-vh-100 text-white'>
                    <div className='continer'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2>Trade Pairs</h2>
                                </div>
                            </div>
                            <div className='col-lg-12 mt-2'>
                                {/* <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='text-end mb-2'>
                                            <button className='liquidity-btn-1' onClick={() => { handleClear() }}>Clear</button>
                                        </div>
                                        <div className='custom-add-1-inside'>
                                            <form>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Chain</label>
                                                    <div class="col-sm-10">
                                                        <Select
                                                            value={selectedOption}
                                                            onChange={handleChange}
                                                            options={options}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">AddressA</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" value={tradeData.token_A_address} id="inputPassword" placeholder='addressA' onChange={(e) => { handleChangeAddress(e, 'token_A_address') }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">AddressB</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" value={tradeData.token_B_address} id="inputPassword" placeholder='addressB' onChange={(e) => { handleChangeAddress(e, 'token_B_address') }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">NameA</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.name_A_token} placeholder='NameA' onChange={(e) => { handleChangeName(e, 'name_A_token') }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">NameB</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.name_B_token} placeholder='NameB' onChange={(e) => { handleChangeName(e, 'name_B_token') }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">SymbolA</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.symbol_A_token} placeholder='symbolA' onChange={(e) => { handleChangeSymbol(e, "symbol_A_token") }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">SymbolB</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.symbol_B_token} placeholder='symbolB' onChange={(e) => { handleChangeSymbol(e, "symbol_B_token") }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">tokenA Logo</label>
                                                    <div class="col-sm-10">
                                                        <input type="file" class="form-control input-text-1" id="inputPassword" placeholder='symbolB' />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">tokenB Logo</label>
                                                    <div class="col-sm-10">
                                                        <input type="file" class="form-control input-text-1" id="inputPassword" placeholder='symbolB' />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Ownpair status </label>
                                                    <div class="col-sm-10">
                                                        <Select
                                                            value={selectedOptionOwnPair}
                                                            onChange={handleChangeOwnPair}
                                                            options={optionsOwnPair}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='text-center'>
                                                <button className='liquidity-btn-1' onClick={() => {
                                                    handleAddNewPair(tradeData);
                                                }}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='row '>
                                    <div className='col-lg-12'>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <ul class="nav nav-pills mb-5 mt-5" id="pills-tab" role="tablist">
                                                {/* <li class="nav-item" role="presentation">
                                                    <button class="nav-link  fees-button" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" onClick={() => { setTableStatus(0) }} aria-selected="true">All Pairs</button>
                                                </li> */}
                                                <li>
                                                    <button class="nav-link text-white fees-button" type="button" onClick={Addnewpairdatas}>Add Pair</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    {/* <button class="nav-link fees-button active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" onClick={() => { setTableStatus(1) }} aria-selected="false">Trade pairs</button> */}
                                                </li>
                                                <li>
                                                    <div class="dropdown custom-coin-1-img">
                                                        <button type="button" class=" px-3 rounded-4 navtab-button dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">

                                                            <BsPlusCircle className='me-2 mb-1' />Networks</button>
                                                        <ul class="dropdown-menu " style={{ cursor: "pointer" }} >
                                                            {/* 'Ethereum''BNB Smart Chain''Polygon''D Chain Mainnet' */}
                                                            <li onClick={() => { showCheck(1) }}>
                                                                <a class="dropdown-item" onClick={() => { showCheck(1) }} >
                                                                    {chainListTableData.includes('Ethereum') && chainIdList.includes(1) ?
                                                                        <FaCheck className='check-list' /> :
                                                                        <FaCheck className='check-list1' />
                                                                    }
                                                                    <img src={ethereum} className='dropdown-icon me-2' alt='ethereum'></img>Ethereum
                                                                </a>
                                                            </li>
                                                            <li ><a class="dropdown-item" onClick={() => { showCheck(2) }} >
                                                                {chainListTableData.includes('BNB Smart Chain') && chainIdList.includes(56) ?
                                                                    <FaCheck className='check-list' /> :
                                                                    <FaCheck className='check-list1' />
                                                                }
                                                                <img src={bnb} className='dropdown-icon me-1' alt='bnb'></img>BNB Smart Chain
                                                            </a></li>
                                                            <li><a class="dropdown-item" onClick={() => { showCheck(3) }}>
                                                                {chainListTableData.includes('Polygon') && chainIdList.includes(137) ?
                                                                    <FaCheck className='check-list' /> :
                                                                    <FaCheck className='check-list1' />
                                                                }

                                                                <img src={polygon} className='dropdown-icon me-2' alt='polygon'></img>Polygon
                                                            </a></li>
                                                            <li><a class="dropdown-item" onClick={() => { showCheck(4) }}>
                                                                {chainListTableData.includes('D Chain Mainnet') && chainIdList.includes(5272) ?
                                                                    <FaCheck className='check-list' /> :
                                                                    <FaCheck className='check-list1' />
                                                                }

                                                                <img src={dcx} className='dropdown-icon me-2' alt='arbitrumlogo'></img>Dcx
                                                            </a></li>

                                                        </ul>
                                                    </div>
                                                </li>


                                            </ul>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    {/* <div class="d-flex align-items-center mb-3 ">
                                                    <span class="pe-2" id="basic-addon1"><IoIosSearch className='input-search-icon' /></span>
                                                    <input type="text" class="form-control ps-4" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => { tableStatus == 0 ? setSearchInPositionTable(e.target.value) : setSearchInPositionTableTrade(e.target.value) }} />
                                                </div> */}
                                                    <div className="d-flex align-items-center position-relative">
                                                        <span
                                                            className="position-absolute start-0 ps-2"
                                                            id="basic-addon1"
                                                            style={{ pointerEvents: 'none', top: '50%', transform: 'translateY(-50%)' }}
                                                        >
                                                            <IoIosSearch className="input-search-icon" />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            className="form-control ps-4"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            aria-describedby="basic-addon1"
                                                            onChange={(e) => {
                                                                tableStatus === 0
                                                                    ? setSearchInPositionTable(e.target.value)
                                                                    : setSearchInPositionTableTrade(e.target.value);
                                                            }}
                                                            style={{ paddingLeft: '2rem' }} // Adjust padding to fit icon
                                                        />
                                                    </div>


                                                </div>
                                            </div>

                                        </div>


                                        <div class="tab-content" id="pills-tabContent">
                                            {/* <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                                <div className='liquidity-table-1'>
                                                    <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense />
                                                </div>
                                                <Tooltip id="Tradepair-tooltip" place='bottom' />
                                            </div> */}
                                            <div class="tab-pane fade  show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                <div className='liquidity-table-1'>
                                                    <DataTable columns={columnstwo} data={datatwo} theme="solarized" defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense />
                                                </div>
                                                <Tooltip id="Tradepair-tooltip" place='bottom' />
                                            </div>
                                            {/* tradeDataUpdate */}

                                            <Modal
                                                show={show}
                                                onHide={handleClose}
                                                backdrop="static"
                                                keyboard={false}
                                                className="custom-modal-12"
                                            >
                                                <Modal.Header closeButton className='border-0'>
                                                    <Modal.Title>Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword1" className="col-sm-2 col-form-label">
                                                            AddressA
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword1"
                                                                value={tradeDataUpdate.token_A_address}
                                                                placeholder='addressA'
                                                                onChange={(e) => { handleChangeAddressUpdate(e, 'token_A_address') }}
                                                                name='title'
                                                            />
                                                            {upadtePairError.token_A_address ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.token_A_address}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword2" className="col-sm-2 col-form-label">
                                                            AddressB
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword2"
                                                                value={tradeDataUpdate.token_B_address}
                                                                placeholder='AddressB'
                                                                onChange={(e) => { handleChangeAddressUpdate(e, 'token_B_address') }}
                                                                name='AddressB'
                                                            />
                                                            {upadtePairError.token_B_address ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.token_B_address}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            NameA
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                placeholder='NameA'
                                                                value={tradeDataUpdate.name_A_token}
                                                                name='NameA'
                                                                onChange={(e) => { handleChangeNameUpdate(e, 'name_A_token') }}
                                                            />
                                                            {upadtePairError.name_A_token ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.name_A_token}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            NameB
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                value={tradeDataUpdate.name_B_token}
                                                                placeholder='NameB'
                                                                name='NameB'
                                                                onChange={(e) => { handleChangeNameUpdate(e, 'name_B_token') }}
                                                            />
                                                            {upadtePairError.name_B_token ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.name_B_token}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            SymbolA
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                placeholder='SymbolA'
                                                                value={tradeDataUpdate.symbol_A_token}
                                                                name='SymbolA'
                                                                onChange={(e) => { handleChangeSymbolUpdate(e, "symbol_A_token") }}
                                                            />
                                                            {upadtePairError.symbol_A_token ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.symbol_A_token}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            SymbolB
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                placeholder='SymbolB'
                                                                value={tradeDataUpdate.symbol_B_token}
                                                                name='SymbolB'
                                                                onChange={(e) => { handleChangeSymbolUpdate(e, "symbol_B_token") }}
                                                            />
                                                            {upadtePairError.symbol_B_token ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.symbol_B_token}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            Logo_A
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="file"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                placeholder='SymbolB'
                                                                // value={tradeDataUpdate.logoURI_A}
                                                                name='SymbolB'
                                                                accept='image/*'
                                                                onChange={(e) => { handleChangeLogoUpdate(e, "logoURI_A", "Logo_A") }}
                                                            />
                                                            {upadtePairError.logoURI_A ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.logoURI_A}
                                                                </span>
                                                            ) : null}
                                                            <img src={`${Upadteimage.Logo_A}`} alt="Token B Preview" className='token-size' />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                                                            Logo_B
                                                        </label>
                                                        <div className="col-sm-10">
                                                            <input
                                                                type="file"
                                                                className="form-control input-text-1"
                                                                id="inputPassword3"
                                                                placeholder='SymbolB'
                                                                // value={tradeDataUpdate.symbol_B_token}
                                                                name='SymbolB'
                                                                accept='image/*'
                                                                onChange={(e) => { handleChangeLogoUpdate(e, "logoURI_B", "Logo_B") }}
                                                            />
                                                            {upadtePairError.logoURI_B ? (
                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                    {upadtePairError.logoURI_B}
                                                                </span>
                                                            ) : null}
                                                            <img src={`${Upadteimage.Logo_B}`} alt="Token B Preview" className='token-size' />
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className="justify-content-center border-0">
                                                    <Button variant="primary" className='liquidity-btn-1' onClick={() => {
                                                        updateSubmit();
                                                    }}>Update</Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <>
                                                {/* <Button variant="primary" onClick={handleShow1}>
                                                    Launch static backdrop modal
                                                </Button> */}

                                                <Modal
                                                    show={show1}
                                                    onHide={handleClose1}
                                                    backdrop="static"
                                                    keyboard={false}
                                                    className="custom-modal-12"
                                                >
                                                    <Modal.Header >
                                                        <Modal.Title>Add Pair</Modal.Title>
                                                        <div className='text-end mb-2 ms-auto'>
                                                            <button className='liquidity-btn-1 me-2' onClick={() => { handleClear() }}><GrClearOption /></button>
                                                            <button aria-label='close' className='btn text-white' onClick={() => { handleClose1() }}><RiCloseLargeLine /></button>
                                                        </div>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                {/* <div className='text-end mb-2'>
                                                                    <button className='liquidity-btn-1'  onClick={() => { handleClear() }}><GrClearOption  /></button>
                                                                </div> */}
                                                                <div className='custom-add-1-inside'>
                                                                    <form>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">Chain</label>
                                                                            <div class="col-sm-10">
                                                                                <Select
                                                                                    value={selectedOption}
                                                                                    onChange={handleChange}
                                                                                    options={options}
                                                                                    styles={customStyles}
                                                                                />
                                                                            </div>
                                                                            {pairerrors.chain_Name ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.chain_Name}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">AddressA</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" value={tradeData.token_A_address} id="inputPassword" placeholder='addressA' onChange={(e) => { handleChangeAddress(e, 'token_A_address') }} />
                                                                            </div>
                                                                            {pairerrors.token_A_address ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.token_A_address}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">AddressB</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" value={tradeData.token_B_address} id="inputPassword" placeholder='addressB' onChange={(e) => { handleChangeAddress(e, 'token_B_address') }} />
                                                                            </div>
                                                                            {pairerrors.token_B_address ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.token_B_address}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">NameA</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.name_A_token} placeholder='NameA' onChange={(e) => { handleChangeName(e, 'name_A_token') }} />
                                                                            </div>
                                                                            {pairerrors.name_A_token ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.name_A_token}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">NameB</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.name_B_token} placeholder='NameB' onChange={(e) => { handleChangeName(e, 'name_B_token') }} />
                                                                            </div>
                                                                            {pairerrors.name_B_token ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.name_B_token}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">SymbolA</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.symbol_A_token} placeholder='symbolA' onChange={(e) => { handleChangeSymbol(e, "symbol_A_token") }} />
                                                                            </div>
                                                                            {pairerrors.symbol_A_token ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.symbol_A_token}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">SymbolB</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" value={tradeData.symbol_B_token} placeholder='symbolB' onChange={(e) => { handleChangeSymbol(e, "symbol_B_token") }} />
                                                                            </div>
                                                                            {pairerrors.symbol_B_token ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.symbol_B_token}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">tokenA Logo</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="file" class="form-control input-text-1" id="inputPassword" placeholder='symbolB' onChange={(e) => { handleChangeLogo(e, "logoURI_A", "preview_A") }} />
                                                                            </div>
                                                                            {pairerrors.logoURI_A ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.logoURI_A}
                                                                                </span>
                                                                            ) : null}
                                                                            {preview.preview_A && (
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-10 offset-sm-2">
                                                                                        <img src={preview.preview_A} alt="Token A Preview" className='token-size' />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">tokenB Logo</label>
                                                                            <div class="col-sm-10">
                                                                                <input type="file" class="form-control input-text-1" id="inputPassword" placeholder='symbolB' onChange={(e) => { handleChangeLogo(e, "logoURI_B", "preview_B") }} />
                                                                            </div>
                                                                            {pairerrors.logoURI_B ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.logoURI_B}
                                                                                </span>
                                                                            ) : null}
                                                                            {preview.preview_B && (
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-10 offset-sm-2">
                                                                                        <img src={preview.preview_B} alt="Token A Preview" className='token-size' />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-2 col-form-label">Ownpair status </label>
                                                                            <div class="col-sm-10">
                                                                                {/* <Select
                                                                                    value={selectedOptionOwnPair}
                                                                                    onChange={handleChangeOwnPair}
                                                                                    options={optionsOwnPair}
                                                                                    styles={customStyles}
                                                                                /> */}
                                                                                <input type="radio" id="Yes" name="isOwnPair" checked={tradeData.ownpair === "isOwnPair"} value={tradeData.ownpair} onChange={onOptionChange} />
                                                                                <label for="Yes">isOwnPair</label>
                                                                                <input type="radio" id="No" name="isNotOwnPair" checked={tradeData.ownpair === "isNotOwnPair"} value={tradeData.ownpair} onChange={onOptionChange} />
                                                                                <label for="No">isNotOwnPair</label>
                                                                            </div>
                                                                            {pairerrors.ownpair ? (
                                                                                <span style={{ color: "red", fontSize: "smaller" }}>
                                                                                    {pairerrors.ownpair}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    </form>
                                                                    <div className='text-center'>
                                                                        <button className='liquidity-btn-1' onClick={() => {
                                                                            createnewpair(tradeData)
                                                                        }}>Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    {/* <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose1}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary">Understood</Button>
                                                    </Modal.Footer> */}
                                                </Modal>
                                            </>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TradePair;