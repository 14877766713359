import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './seperate/Dashboard';
import "../src/assets/css/Dashboard.css"
import Addblog from './seperate/Addblog';
import Editblog from './seperate/Editblog';
import Login from './seperate/Login';
import Register from './seperate/Register';
import Otp from './seperate/Otp';
import Forgetpassword from './seperate/Forgetpassword';
import ResetPassword from './seperate/ResetPassword';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from './seperate/404Page';
import ProtectedRoute from './auth/ProtectedRoute';
import { WagmiConfig, createConfig, configureChains, sepolia } from 'wagmi'
import { mainnet, polygon, bsc } from "wagmi/chains";
import config from "./config/config";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { LedgerConnector } from 'wagmi/connectors/ledger';

function App() {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, polygon, bsc,config.DCX, ],
    [alchemyProvider({ apiKey: 'f4FBGOtlTPoqJ2ZR-zanG3k9lgU38KCu' }), publicProvider()],
  )

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      new LedgerConnector({
        // chains,
        options: {
          projectId: '1f0b9efb280db82bff5edc4689ffa76a',
        }
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: '1f0b9efb280db82bff5edc4689ffa76a',
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'D-Ecosystem',
          jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/f4FBGOtlTPoqJ2ZR-zanG3k9lgU38KCu',
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: "trustwallet",
          shimDisconnect: true,
          getProvider: () =>
            typeof window !== "undefined" ? window.trustwallet : undefined
        },
      }),
    ],
    publicClient,
    webSocketPublicClient,
  })

  global.Buffer = global.Buffer || require('buffer').Buffer;
  return (
    <div className="App">
      <BrowserRouter>
       <WagmiConfig config={wagmiConfig}>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/dashboard/profile" />} /> */}
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/register" element={<Register />} /> */}
          <Route exact path="/otp" element={<Otp />} />
          <Route exact path="/forgetpassword" element={<Forgetpassword />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route exact path="/dashboard/fees" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/dashboard/addpools" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/dashboard/TradePair" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/dashboard/profile" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/dashboard/form" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/addblog" element={<ProtectedRoute><Addblog /></ProtectedRoute>} />
          <Route exact path="/editblog/:id" element={<ProtectedRoute><Editblog /></ProtectedRoute>} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
        </WagmiConfig>
      </BrowserRouter>
    </div>
  );
}

export default App;
